import React from "react"
import { Error } from "~sections/utility"
import { PageWrapper, SEO, Link } from "~components"
import FooterBlock from "~components/FooterBlock"
import FooterSimple from "~components/FooterSimple"

import Config from "~data/config"
import { useSiteMetadata } from "~hooks/use-site-metadata"

const header = {
  headerClasses:
    "site-header site-header--menu-end dark-header site-header--sticky position-relative site-header--menu-sep",
  containerFluid: false,
  darkLogo: false,
  buttonBlock: (
    <Link
      title={"Gift Cards"}
      className="btn btn-outline-secondary rounded-pill py-3 ms-auto d-none d-xs-inline-flex"
      link="https://www.vagaro.com/Users/BusinessWidget.aspx?enc=MMLjhIwJMcwFQhXLL7ifVGOwOvJkWSUu0HWpFHcIQKdziAzzXN6MkSKz4rU2l1PnM3MEg0m2hqPQWlqNqXcA14R4IqMe4FpU2ds9IcrYPL9zxOg7Q54n04kDy63+MfHDu99ov59H8Ij505cqCz81BQN8bMWMaAbHb1TtZMNcNNSD9cEnGtY8UzntIRtke/hIaN74IzpmrSgy1UUsr9IthvlgfvKwPpOVYw0Gn2GCeJ9yuXa9X+vS+kN1VJfSGxizZv7xHtZd+Ct3dpE9VpNzge6cI16+VNesvPxgK3VO+eoaNwXwze/CLbVYlz5t4BHEKmHwTiVfLUvozETgr+SQzQ==">
      <span>
        <i className="fa fa-gift"></i>
        {"  "}Gift Cards
      </span>
    </Link>
  ),
}

export default function ErrorPage() {
  const siteMetadata = useSiteMetadata()

  const { title: siteTitle, siteUrl, siteLanguage, siteLocale } = useSiteMetadata()

  return (
    <PageWrapper headerConfig={header} innerPage={true}>
      <SEO
        titleTemplate={`${siteTitle}`}
        titleSeparator={`-`}
        title={`Not Found - ${Config.siteName}`}
        description={`${Config.siteDescription}`}
        type={`${siteMetadata.type}`}
        pathname={siteUrl}
        location="/"
        siteLanguage={siteLanguage}
        siteLocale={siteLocale}
        indexed="false"
      />
      <Error />
      <FooterBlock />
      <FooterSimple />
    </PageWrapper>
  )
}
