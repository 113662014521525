import styled from "styled-components/macro"
import { Link } from "~components"
import { Box } from "~styled"

const Footer = styled(Box)`
  background-color: var(--bs-black);
  position: relative;
  z-index: 1;
`

Footer.Logo = styled(Box)`
  text-align: center;
  @media (min-width: 768px) {
    text-align: left;
  }
`

Footer.SocialShare = styled(Box).attrs({ className: "footer-social-share list-unstyled", as: "ul" })`
  padding: 0;
  position: relative;
  margin: 0;
`
Footer.SocialShareItem = styled(Box).attrs({ className: "list-items", as: "li" })`
  display: inline-flex;
  align-items: center;
  padding: 0 10px;

  a {
    min-width: 34px;
    min-height: 34px;
    border-radius: 500px;
    color: var(--bs-white);
    font-size: 18px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: 0.4s;
    &:hover {
      box-shadow: 0 10px 20px rgba(253 52 110, 0.25);
      background-color: var(--bs-secondary);
      color: #fff;
    }
  }
  &:first-child {
    a:before {
      display: none;
    }
  }
`

Footer.Copyright = styled(Box)`
  text-align: center;
  border-top: 1px solid rgba(38, 39, 41, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  color: var(--bs-white);

  @media (min-width: 768px) {
    justify-content: space-between;
  }
`

Footer.CopyrightText = styled(Box)`
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 22px;
  margin-bottom: 20px;
  @media (min-width: 768px) {
    margin-right: 20px;
    margin-bottom: 0;
  }
`

Footer.Box = styled(Box)``
export default Footer
