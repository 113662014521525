import { graphql, useStaticQuery } from "gatsby"
export const useSiteMetadata = () => {
  const {
    site: { siteMetadata },
  } = useStaticQuery(
    graphql`
      query SITE_METADATA_QUERY {
        site {
          siteMetadata {
            title
            description
            image
            nameShort
            author
            name
            nameShort
            siteUrl
            twitterhandle
            facebook
            twitter
            instagram
            yelp
            googleAnalyticsID
          }
        }
      }
    `
  )
  return siteMetadata
}
