import React from "react"
import { Container } from "react-bootstrap"
import { Link } from "~components"
import { Images } from "~data"
import Footer from "./style"
import Config from "~data/config"

export default function FooterBlock() {
  return (
    <Footer>
      <Container>
        <Footer.Box pt="80px" pb="40px">
          <Footer.Logo>
            <Link link="/">
              <img src={Images.Logo.White} alt={`${Config.nameShort} Logo`} className="w-50" />
            </Link>
          </Footer.Logo>
          <Footer.Text as="p">6413 N Kinzua Ave, Chicago, IL 60646</Footer.Text>
          <Footer.Text as="p">(773) 763-1212</Footer.Text>
          <Footer.Text as="p">keepcalm@essence-medspa.com</Footer.Text>
        </Footer.Box>
      </Container>
    </Footer>
  )
}
