import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Images } from "~data"
import Footer from "./style"
import { Link } from "gatsby"

import Config from "~data/config"

export default function FooterSimple() {
  return (
    <Footer>
      <Container>
        <Footer.Box>
          <Footer.Copyright pt="40px" pb="40px">
            <Footer.CopyrightText>
              © {new Date().getFullYear()} {Config.name}. All Rights Reserved. By{" "}
              <Link to="https://www.onestingray.com/" className={""} style={{ color: "#888888" }} target="_blank">
                One Stingray
              </Link>
            </Footer.CopyrightText>
            <Footer.SocialShare>
              <Footer.SocialShareItem>
                <a
                  href={Config.socialLinks.facebook}
                  title={`${Config.nameShort} on Facebook`}
                  target="_blank"
                  rel="noopener noreferrer">
                  <i className="fab fa-facebook-square" />
                  <span className="d-none">{Config.nameShort} on Facebook</span>
                </a>
              </Footer.SocialShareItem>
              <Footer.SocialShareItem>
                <a
                  href={Config.socialLinks.twitter}
                  title={`${Config.nameShort} on Twitter`}
                  target="_blank"
                  rel="noopener noreferrer">
                  <i className="fab fa-twitter" />
                  <span className="d-none">{Config.nameShort} on Twitter</span>
                </a>
              </Footer.SocialShareItem>
              <Footer.SocialShareItem>
                <a
                  href={Config.socialLinks.instagram}
                  title={`${Config.nameShort} on Instagram`}
                  target="_blank"
                  rel="noopener noreferrer">
                  <i className="fab fa-instagram" />
                  <span className="d-none">{Config.nameShort} on Instagram</span>
                </a>
              </Footer.SocialShareItem>
              <Footer.SocialShareItem>
                <a
                  href={Config.socialLinks.yelp}
                  title={`${Config.nameShort} on Yelp`}
                  target="_blank"
                  rel="noopener noreferrer">
                  <i className="fab fa-yelp" />
                  <span className="d-none">{Config.nameShort} on Yelp</span>
                </a>
              </Footer.SocialShareItem>
            </Footer.SocialShare>
          </Footer.Copyright>
        </Footer.Box>
      </Container>
    </Footer>
  )
}
